import React, { useRef, useState } from 'react';

const FileSelector = ({selfRightsLvl, fetchAgain, domain}) => {
  const inputFileRef = useRef(null);

  const handleClick = () => {
    inputFileRef.current.click();
  };

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      readFileToArray(file);
    }
  };

  // Функция для чтения файла и записи содержимого в массив
  const readFileToArray = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const array = data.trim().split('\n');
      addNewDecoders(array)
    };
    reader.onerror = (err) => {
      console.error('Ошибка при чтении файла:', err);
    };
    reader.readAsText(file);
  };

  const addNewDecoders = async(list) => {
    const requestParams = {
      method: "PUT",
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ decoders: list })
    }
    try{
      const responce = await fetch(`https://${domain}/api/decoders`, requestParams);
      if(!responce.ok) throw new Error();
      fetchAgain();
    }catch(e){
      console.error(e);
    }
  }

  return (
    <div className='center-box' style={selfRightsLvl < 32 ? {display: 'none'} : {}}>
      <button onClick={handleClick} className='requestBtn'>Upload the file</button>
      <input
        type="file"
        ref={inputFileRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </div>
  );
};

export default FileSelector;
