export const eight = (lvl) => {
    return (
      lvl === 8 || 
      lvl === 9 || 
      lvl === 10 || 
      lvl === 11 ||
      lvl === 12 ||
      lvl === 13 ||
      lvl === 14 ||
      lvl === 15 ||
      lvl === 24 || 
      lvl === 25 ||
      lvl === 26 ||
      lvl === 27 || 
      lvl === 28 ||
      lvl === 29 || 
      lvl === 30 || 
      lvl === 31
    )
  }
