import {fetchList} from './Mux';

export const fetchMuxData = async (setMuxes, domain) => {
    try{
        const requested = await fetchList(domain);
        setMuxes(requested || []);
    }catch(e){
        console.error(e);
        setMuxes([])
    }   
}
