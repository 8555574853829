import EditBtn from './EditBtn'
import AddMod from './Modal'  
import './style.css'
import React, {useState, useEffect} from 'react';
import Pagination from '../components/Pagination';
import { fetchPackagesData } from './../service/packages/comp';
import { fetchTypes } from '../service/types/comp';
const columns = [
  { id: 'pack_name', label: 'Name of Package' },
  { id: 'price', label: 'Price' },
  { id: 'type', label: 'Type' },
  { id: 'created', label: 'Date of Creating' },
  { id: 'btn', label: '' }
];

const Packages = ({domain, selfRightsLvl, dataL, setDataL}) => {
    const [packages, setPackages] = useState(dataL);
    const [name, setName] = useState('');

    const [types, setTypes] = useState([]);

    const getTypes = () => {
      fetchTypes(setTypes, domain);
    } 
    const fetchList = () => {
      fetchPackagesData(name, pageNumber, setPackages, domain);
    }

    ////////////////
    // page loading 
    const [pageNumber, setPageNumber] = useState(0);
    
    const pageCount = Math.ceil(packages.count !== undefined ? packages.count / 10 : 0);

    const styler = (id) => {
      if (id === 'btn') return selfRightsLvl >= 32 ? {padding: 1.5, border: 0, width: '5%'} : {display: 'none'}
      else return {padding: 'auto'}
    }

    const displayData = packages.packs && packages.packs.map((row, index) => (
      <tr key={row.id}>
        {columns.map(column => 
          <td key={column.id} style={styler(column.id)}> 
            {
              column.id !== 'btn' ?
                (row[column.id] !== '' ?
                  row[column.id] 
                : 
                  'None'
                ) 
              : 
                <EditBtn 
                fetchAgain={fetchList}
                countries={packages.packs[index].countries} 
                domain={domain} 
                id={packages.packs[index].pack_id}
                name={packages.packs[index].pack_name}
                type={packages.packs[index].type}
                types={types}
                fetchTypesAgain={setTypes}
                price={packages.packs[index].price}/>
            }
          </td>
        )}
      </tr>
    ));

    ////////////////
    // actions
    const fillForms = (e) => {
      setName(e.target.value);
    }
    useEffect(() =>{
      if (pageNumber > 0){
        fetchList();
      }
      getTypes();
    }, [pageNumber])

    useEffect(() =>{
      setDataL(packages);
    }, [packages])

    return(
      <div className="packages box"> 
        <input type="text" className='textField' placeholder='Find by name' onChange={fillForms}/>
        <button 
        onClick={fetchList} 
        className='requestBtn'>Get Filtered list</button>
        <div className='op-table'>
          <table>
            <thead>
              <tr>
                {columns.map(column => 
                  <th key={column.id} style={styler(column.id)}>
                    {column.label}
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {displayData}
            </tbody>
          </table>
          <Pagination pageNumber={pageNumber} pageCount={pageCount} setPageNumber={setPageNumber}/> 
        </div>
        <AddMod selfRightsLvl={selfRightsLvl} fetchAgain={fetchList} domain={domain} types={types} fetchTypesAgain={setTypes}/>
        
      </div>
    )
}
export default Packages;