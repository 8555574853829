export const fetchList = async (domain) => {
    try {
        const responce = await fetch(`https://${domain}/api/currency`, {method: 'GET'});
        if (!responce.ok) throw new Error();
        const data = responce.json();
        return data;
    }catch (err) {
        console.error(err);
        return {}
    }
}
