import { useState } from 'react';
import bin from './recycle-bin-container.png';

const BinBtn = ({fetchAgain, email, first, last, pageNumber, domain, transaction}) => {
    const [modal, setModal] = useState(false);
    const modalSet = () => {
        setModal(!modal);
        document.body.style.overflow = !modal ? 'hidden' : 'auto';
    }

    const del = async () => {
        try {
          const response = await fetch(`https://${domain}/api/payments/${transaction} `, {
            method: 'DELETE'
          });
          if(!response.ok) throw new Error();
        }catch(e){console.error(e);}
      }

    const fetcher = () => {
        del();
        fetchAgain(email, first, last, pageNumber, domain);
        modalSet();
    }
    return (
        <div>
            <img className='bin-icon' onClick={modalSet} src={bin}  alt="bin" />
            {modal && <div className='modal-window'>
                <div className='modal-box'>
                    <h1>Confirm the action</h1>
                    Delete payment?
                    <div className='modal-btns'>
                        <button className='closeBtn' onClick={fetcher}>Delete</button>
                        <button className='requestBtn' onClick={modalSet}>Cancel</button>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default BinBtn