import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { one } from './service/rights/one';
import { sixteen } from './service/rights/sexteen';
const Header = ({rights, domain}) => {
  const location = useLocation();
  const currentURL = location.pathname;
  
  const [signed, setSigned] = useState(false);

  const out = async () => {
    try {
      const response = await fetch(`https://${domain}/api/operator/logout`, { method: 'POST' });
      if (!response.ok) throw new Error();
    } catch (error) {
      console.error('Произошла ошибка:', error.message);
    }
  }

  useEffect(() => {
    setSigned(currentURL !== '/login');
  }, [location]);

  return (
    signed && (
      <div className='box'>
        <div className='header'>
          <div className='nav-btns'>
            {(rights < 32 ? one(rights) : true) && <Link className={currentURL === '/subscribers' ? 'links nav-link choosed-link' : 'links nav-link'} to='/subscribers'>Subscribers</Link>}
            {/* {(rights < 32 ? two(rights)  : true) && <Link className={currentURL === '/payments' ? 'links nav-link choosed-link' : 'links nav-link'} to='/payments'>Payment</Link>}   */}
            <Link className={currentURL === '/packages' ? 'links nav-link choosed-link' : 'links nav-link'} to='/packages'>Packages</Link>
            <Link className={currentURL === '/decoders' ? 'links nav-link choosed-link' : 'links nav-link'} to='/decoders'>Decoders</Link>
            <Link className={currentURL === '/password' ? 'links nav-link choosed-link' : 'links nav-link'} to='/password'>Password</Link>
            {(rights < 32 ? sixteen(rights) : true) && <Link className={currentURL === '/constructor' ? 'links nav-link choosed-link' : 'links nav-link'} to='/constructor'>Constructor</Link>}
            <Link className={currentURL === '/currency' ? 'links nav-link choosed-link' : 'links nav-link'} to='/currency'>Currency</Link>
            {rights >= 32 && <Link className={currentURL === '/reports' ? 'links nav-link choosed-link' : 'links nav-link'} to='/reports'>Reports</Link>}
            {(rights < 32 ? sixteen(rights) : true) && <Link className={currentURL === '/operators' ? 'links nav-link choosed-link' : 'links nav-link'} to='/operators'>Operators</Link>}
          </div>
          <Link to='/login' className='logout-btn requestBtn' onClick={out}>Log Out</Link>
        </div>
      </div>
    )
  );
}

export default Header;
