export const fetchList = async (name, email, phone, pageNumber, domain) => {
  const requestBody = {
      name: name, 
      email: email,
      phone: phone,
      limit: 10,
      offset: pageNumber * 10
    }
  const requestParams = {
    method: "POST",
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(requestBody)
  }
  try{
    const response = await fetch(`https://${domain}/api/user/list`, requestParams);
    if (!response.ok){
      throw new Error()
    }
    const data = await response.json();
    return data;
  }catch(e){
    console.error(e.message);
    return {};
  }
}