import React, {useEffect, useState} from 'react';
import Autocomplete from './Auto';
import TypeAuto from './../components/AutoComplete';

export default function BasicModal({countries, domain, id, name, type, types, price, fetchAgain, fetchTypesAgain }) {
  const [open, setOpen] = useState(false);
  const setStatus = (param) => {
    setOpen(param);
    document.body.style.overflow = param ? "hidden" : "auto";
  }
  const closeModal = () => {
    setStatus(false);
  }

  const [packName, setPackName] = useState('');
  const [packPrice, setPackPrice] = useState(0)
  const [packType, setPackType] = useState(type);
  const [packCountries, setPackCountries] = useState(countries)

  const handleNameChange = (e) => {
    setPackName(e.target.value)
  }
  const handlePriceChange = (e) => {
    setPackPrice(parseFloat(e.target.value))
  }

  const findTypIdByName = () => {
    const index = types.findIndex(item => item.typ_name === packType);
    return index !== -1 ? types[index].typ_id : null;
  };

  const handleTypeChange = (param) => {
    setPackType(param)
  }

  const setCountryList = (param) => {
    setPackCountries(param)
  }

  const saveChanges = async () => {
    const requestBody = {
      pack_id: id,
      pack_name: packName,
      typ_id: findTypIdByName(),
      price: packPrice,
      countries: packCountries,
    }
    const requestParams ={
      method: "POST",
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(requestBody)
    }
    try {
      const response = await fetch(`https://${domain}/api/packages`, requestParams);
      if(!response.ok) throw new Error();
      closeModal();
      fetchAgain();
    }catch(e){console.error(e);}
  }

  useEffect(() => {
    setPackName(name);
    setPackPrice(price);
  }, [open])

  return (
    <div>
      <button className='requestBtn' onClick={() => {setStatus(true)}}>Edit</button>

      {open && 
        <div className="modal-window">
          <div className='edit-modal-box modal-box'>
              <div className='input-areas'>
                <label>Name of package</label>
                <input className='inputs' type='text' value={packName} onChange={(e) => {handleNameChange(e)}}/>
              </div>
              <div className='input-areas'>
                <label>Price</label>
                <input className='inputs' type='number' step="any"  value={packPrice} onChange={(e) => {handlePriceChange(e)}}/>
              </div>  
              <TypeAuto types={types.map(t => t.typ_name)} type={type} setType={handleTypeChange} domain={domain} fetchAgain={fetchTypesAgain}/>
              <Autocomplete iso2={countries} setCounties={setCountryList}/>
              <div style={{display:'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px'}}>
                <button className='requestBtn' onClick={saveChanges}>Save</button>
                <button className='closeBtn' onClick={closeModal}>Cancel</button>
              </div>
              
          </div>
        </div>
      }
    </div>
  );
}
