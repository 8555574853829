import React, {useState, useEffect} from 'react';
import TypeAuto from './../components/AutoComplete';
const Modal = ({selfRightsLvl, fetchAgain, domain, types, fetchTypesAgain}) => {
    const [modal, setModal]= useState(false)
    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const [type, setType] = useState('');
    const modalSet = () => {
      setModal(!modal);
      document.body.style.overflow = !modal ? 'hidden' : 'auto';
    }
    const nameChange = (event) => {
      setName(event.target.value)
    };
    const priceChange = (event) => {
        setPrice(parseFloat(event.target.value));
      }; 
  
    const handleTypeSet = (param) => {
      setType(param);
    }; 
    
    const findTypIdByName = () => {
      const index = types.findIndex(item => item.typ_name === type);
      return index !== -1 ? types[index].typ_id : null;
    };

    const addNewPackage = async() => {
      const requestBody = {
        pack_name: name,
        typ_id: findTypIdByName(),
        price: price,
        countries: null
      }
      const requestParams = {
        method: "PUT",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(requestBody)
      }
      
      try{
        const responce = await fetch(`https://${domain}/api/packages`, requestParams);
        modalSet();
        if(!responce.ok) throw new Error();
        fetchAgain();
      }catch(e){
        console.error(e);
      }
    }

    return(
      <div>
        <div className='center-box' style={selfRightsLvl < 32 ? {display: 'none'} : {}}><button className='requestBtn' onClick={modalSet}>Add</button></div>
        {modal && <div className='modal-window'>
            <div className='modal-box'>
                <div className='input-areas'>
                  <label>Name of Package</label>
                  <input className='inputs' type='text' onChange={nameChange} />
                </div>
                <div className='input-areas'>
                  <label>Price</label>
                  <input className='inputs' type='text' onChange={priceChange}/>
                </div>
                <TypeAuto types={types.map(t => t.typ_name)} type={type} setType={handleTypeSet} domain={domain} fetchAgain={fetchTypesAgain}/>
                <div className='modal-btns'>
                    <button className='requestBtn' onClick={addNewPackage}>Add</button>
                    <button className='closeBtn' onClick={modalSet}>Cancel</button>
                </div>
            </div>
        </div>}
      </div>
    )
}


export default Modal