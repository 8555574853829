import React from 'react';
import CountryList from './../data/CountryList';

const User_infos = ({userData}) =>{
  return(
      <div className='users-info'>
        <div className='input-areas'>
          <label>Name</label>
          <input className='inputs' type='text' value={userData.name || 'None'} />
        </div>
        <div className='input-areas'>
          <label>Surname</label>
          <input className='inputs' type='text' value={userData.surname || 'None'} />
        </div>
        <div className='input-areas'>
          <label>Balance</label>
          <input className='inputs' type='text' value={userData.balance || 'None'} />
        </div>
        <div className='input-areas'>
          <label>Email</label>
          <input className='inputs' type='text' value={userData.email || 'None'}/>
        </div>
        <div className='input-areas'>
          <label>Number of devices</label>
          <input className='inputs' type='text' value={userData.decoders_count || 'None'}/>
        </div>
        <div className='input-areas'>
          <label>Country</label>
          <input className='inputs' type='text' value={CountryList[userData.country] || 'None'} />
        </div>
        <div className='input-areas'>
          <label>Monthly tariff</label>
          <input className='inputs' type='text' value={userData.tarif || 'None'}  />
        </div>
        <div className='input-areas'>
          <label>Phone</label>
          <input className='inputs' type='text' value={userData.phone || 'None'}  />
        </div>
        <div className='input-areas'>
          <label>Last payment</label>
          <input className='inputs' type='text' value={userData.last_payment || 'None'}  />
        </div>
        <div className='input-areas'>
          <label>Date of Creating</label>
          <input className='inputs' type='text' value={userData.created || 'None'}/>
        </div>
        <div className='input-areas'>
          <label>Expiration date</label>
          <input className='inputs' type='text' value={userData.exp_date || 'None'}  />
        </div>
      </div>
  )
}

export default User_infos;


// backgroundColor: '#F6F1F1'