import {fetchList} from './CurrenciesGet';

export const fetchCurrencies = async (domain, setCurrencies) => {
    try{
        const requested = await fetchList(domain);
        setCurrencies(requested || {});
    }catch(e){
        console.error(e);
        setCurrencies({});
    }   
}
