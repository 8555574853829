import { useLocation } from "react-router-dom";

const Loading = () => {
    const loc = useLocation();
    const load = loc.pathname === '/subscribers' ? {top: window.scrollY} : {top: 0}
    return(
        <div className="load-modal" style={load}>
            <div className="spinner"></div>
        </div>
    )
}

export default Loading;