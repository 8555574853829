import {fetchList} from './Operators';

export const fetchOpsData = async (setOps, domain) => {
    try{
        const requested = await fetchList(domain);
        setOps(requested || []);
    }catch(e){
        console.error(e);
        setOps([])
    }   
}
