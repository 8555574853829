import React, { useEffect, useState } from 'react';
import Modal from './Modal';
import Pagination from '../components/Pagination';

import { fetchPacksData } from './../service/constructor/compCons';

const Table = ({domain, selfRightsLvl, checkedL, packsL, setCheckedL}) => {
  const Groups = ["Group 0", "Group 1", "Group 2", "Group 3", "Group 4", "Group 5", "Group 6", "Group 7", "Group 8", "Group 9"]
  const Cs = ["C1", "C2", "C3"]

  const [packs, setPacks] = useState(packsL)
  const [checked, setChecked] = useState(checkedL)
  const [changedChecked, setChangedChecked] = useState([]);

  const handleChange = (value, index, ind) => {
    const update = [...checked]
    update[index][ind] = value;
    let sum = 0;
    update[index].forEach((status, selfIndex) => {
      if (status) sum += 2 ** selfIndex; 
    })
    setChangedChecked(prevState => {
      const existingIndex = prevState.findIndex(item => item.pack_id === packs[index].pack_id);
      if (existingIndex !== -1) {
        const updatedItem = { ...prevState[existingIndex], mask: sum };
        const updatedState = [...prevState];
        updatedState[existingIndex] = updatedItem;
        return updatedState;
      } else {
        return [...prevState, { pack_id: packs[index].pack_id, mask: sum }];
      }
    });
    setChecked(update);
  }

  const fetchList = async () => {
    fetchPacksData(setChecked, setPacks, domain);
  }

  const setMaskFetch = async () => {
    const requestBody = {
      packs: changedChecked
    }
    const requestParams = {
      method: "POST",
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(requestBody)
    }
    if(requestBody.packs.length > 0) {
      try {
        const responce = await fetch(`https://${domain}/api/packages/masks`, requestParams);
        if(!responce.ok) throw new Error(responce.message);
        fetchList();
      }catch(e) {console.error(e);}
    }
  }

  const [pageNumber, setPageNumber] = useState(0);
  const pageCount = Math.ceil(packs && packs.length / 5);
  const displayData = packs && packs.slice(pageNumber * 5, pageNumber * 5 + 5).map(row => {
    const index = packs.indexOf(row);
    return (
      <tr
        key={index}
      >
        <td className='row-name'>
          <Modal name={row.pack_name} id={row.pack_id} domain={domain}/>
        </td>
        {checked[index] !== undefined && checked[index].map((col, ind) => (
          <td key={ind} className='checkbox'> <input onChange={(e) => {handleChange(e.target.checked, index, ind)}} type="checkbox" checked={col} disabled={(row.disabled || false) || selfRightsLvl < 32 }/></td>
        ))}
      </tr>
    )
  }); 

  useEffect(() =>{
    setCheckedL(checked);
  }, [checked])

  return (
    <div style={{ width: '100%', overflow: 'hidden' }}>
      <button className='closeBtn' onClick={fetchList}>Refresh</button> Press to receive actual data from multiplexers
      <table className='constructor-table'>
        <thead>
          <tr>
            <th rowSpan={2}>
              Packages  
            </th>
            {Groups.map((column) => (
                <th align="center" colSpan={3}
                  key={column}
                >
                  {column}
                </th>
            ))}
          </tr>
          <tr>
            {Groups.map((column) => (
                <th colSpan={3}
                  key={column}
                >
                  {Cs.map(lilcolumn => (
                    <span className='sub-col'
                      key={lilcolumn}
                    >
                      {lilcolumn}
                    </span>
                  ))}
                </th>
            ))}
          </tr>
        </thead>
        <tbody>
              {displayData}
        </tbody>
        <tfoot>
          
        </tfoot>
      </table>
      <Pagination pageNumber={pageNumber} pageCount={pageCount} setPageNumber={(param) => {setPageNumber(param)}}/>
      <button style={selfRightsLvl < 32 ? {display: "none"} : {}} className='requestBtn' onClick={setMaskFetch}>Save</button>
    </div>
  );
}


export default Table;