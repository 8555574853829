import React, {useState, useEffect} from 'react';
import Pagination from '../components/Pagination';
import Modal from './Modal';
import CreateModal from './CreateModal';
import './style.css';
import { fetchOpsData } from './../service/operators/comp';
const columns = [
  { id: 'login', label: 'User Name', }, 
  { id: 'name', label: 'Name', }, 
  { id: 'email', label: 'Email', }, 
  { id: 'phone', label: 'Phone', }, 
  { id: 'rights', label: 'Rights', },
  { id: 'btn', }  
];  

const Operators = ({domain, selfRightsLvl, dataL, setDataL}) => {
    const [rows, setRows] = useState(dataL);
    const fetchOpList = async () => {
      fetchOpsData(setRows, domain);
    }  
    const block = (id, row) => {
      if(id === 'btn'){
        return <Modal curUserName={row.login} domain={domain} fetchList={fetchOpList}/>
      } else {
        return row[id] ? row[id] : 'None'
      }
    }
    const [pageNumber, setPageNumber] = useState(0);
    const pageCount = Math.ceil(rows && rows.length / 10);
    const styler = (id) => {
      if(id === 'btn') return selfRightsLvl >= 32 ? {border: 0, width: '5%'} : {display: 'none'}
      else return {}
    }    
    const displayData = rows && rows.slice(pageNumber * 10, pageNumber * 10 + 10).map(row => (
      <tr key={row.id}>
        {columns.map(column => 
          <td style={styler(column.id)} key={column.id}> 
            {block(column.id, row)}
          </td>
        )}
      </tr>
    )); 
    useEffect(() =>{
      setDataL(rows);
    }, [rows])
    return(
      <div className="operators box"> 
          <button className='closeBtn' onClick={fetchOpList}>Refresh</button>
        <div className='op-table'>
          <table>
            <thead>
              <tr>
                {columns.map(column => 
                  <th style={styler(column.id)} key={column.id}>
                    {column.id !== 'btn' ? column.label : <div></div>}
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {displayData}
            </tbody>
          </table>
          <Pagination pageNumber={pageNumber} pageCount={pageCount} setPageNumber={(param) => {setPageNumber(param)}}/> 
        </div>
        <CreateModal selfRightsLvl={selfRightsLvl} fetchList={fetchOpList} domain={domain}/>
      </div>
    )
}

export default Operators;