export const fetchList = async (domain) => {
    try {
      const responce = await fetch(`https://${domain}/api/packages/masks`, {method: 'GET'});
      if(!responce.ok) throw new Error(responce.message);
      const data = await responce.json();
      return data.packs || [];
     
    }catch(e) {
        console.error(e);
        return []
    }
  }
