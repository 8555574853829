export const four = (lvl) => {
    return (
      lvl === 4 || 
      lvl === 5 || 
      lvl === 6 || 
      lvl === 7 ||
      lvl === 12 ||
      lvl === 13 ||
      lvl === 14 ||
      lvl === 15 ||
      lvl === 20 || 
      lvl === 21 ||
      lvl === 22 ||
      lvl === 23 || 
      lvl === 28 ||
      lvl === 29 || 
      lvl === 30 || 
      lvl === 31
    )
  }