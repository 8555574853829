import React, { useState } from 'react';
import Info from './Info';
import Downloaders from './Downloaders';
import Loading from '../components/Loading';
import icon from './excel.png';
import './style.css'

import {fetchSummary} from '../service/reports/Total';
const Reports = ({domain}) => {
  function isValidDate(dateString) {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (!regex.test(dateString)) return false;
    const [year, month, day] = dateString.split('-').map(Number);
    const date = new Date(year, month - 1, day);
    if (date.getFullYear() !== year || date.getMonth() !== month - 1 || date.getDate() !== day) {
      return false;
    }
    return true;
  }
  const [summary, setSummary] = useState({});
  const [first,setFirst] = useState('');
  const [last,setLast] = useState('');
  const [errorModal, setErrorModal] = useState(false);
  const [modal, setModal] = useState(false);
  const handleFirstChange = (e) => {
    setFirst(e.target.value);
  }
  const handleLastChange = (e) => {
    setLast(e.target.value);
  }
  const fetchList = async () => {
    setModal(true);
    try{
      if(isValidDate(first) && isValidDate(last)) {
        setErrorModal(false);
        const responce = await fetchSummary(first, last, domain);
        setSummary(responce);
      }
      else{
        setErrorModal(true);
      }
      setModal(false)
    }catch(e){ console.error(e);}

  } 
  const fetchTable = async (name) => {
      const requestBody = {
        from_date: first, 
        to_date: last
      }
      const requestParams = {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(requestBody)
      }
      setModal(true);
      try{
        const response = await fetch(`https://${domain}/api/report/${name}`, requestParams);
        if (!response.ok){
          throw new Error()
        }
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${name}.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        setModal(false);
      }catch(e){
        console.error(e.message);
      }
  }
  const fetchLastTable = async () => {
    try{
      const response = await fetch(`https://${domain}/api/report/zero-cost`,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      });
      if (!response.ok){
        throw new Error()
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'report.xlsx';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }catch(e){
      console.error(e.message);
    }
  }
  return(
        <div className='box'>
            <div className='reportsHeader'>
                <div className='input-areas'>
                  <label>Date</label>
                  <input placeholder='XXXX-XX-XX'
                  onChange={handleFirstChange}
                  className='inputs' type="text"/>
                </div>
                <div className='input-areas'>
                  <label>Last date</label>
                  <input placeholder='XXXX-XX-XX'
                  className='inputs' type="text"
                  onChange={handleLastChange}
                  />
                </div>
                <button onClick={fetchList} className='requestBtn padBtn'>Submit</button>
                {errorModal && <span className='red'>Incorrect date</span>}
            </div>
            <Info summary={summary}/>
            <Downloaders fetchTable={fetchTable} fetchLastTable={fetchLastTable} icon={icon}/>
            {modal && <Loading/>}
        </div>
    )
}

export default Reports;