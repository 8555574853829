export const fetchList = async (domain) => {
    try{
      const response = await fetch(`https://${domain}/api/operator/list`);
      if(!response.ok) throw new Error()
      const data = await response.json();
      return data.operators;
    }catch(e){
      console.error(e.message);
      return [];
    }
  }  