import {fetchList} from './Subscribers';

export const fetchSubscribersData = async (name, email, phone, pageNumber, setSubscribers, domain) => {
    try{
        const requested = await fetchList(name, email, phone, pageNumber, domain);
        setSubscribers(requested || {});
    }catch(e){
        console.error(e);
        setSubscribers({});
    }   
}
