export const getTypes = async (domain) => {
  try {
    const response = await fetch(`https://${domain}/api/packages/types`, {method: "GET"});
    if (!response.ok){
      throw new Error()
    }
    const data = await response.json();
    return data.pack_types
  } catch(e){
    console.error(e);
    return {};
  }
} 