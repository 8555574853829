export const fetchSummary = async (from_date, to_date, domain) => {
    const requestBody = {
      from_date: from_date, 
      to_date: to_date
    }
    const requestParams = {
      method: "POST",
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(requestBody)
    }
    const response = await fetch(`https://${domain}/api/report/total`, requestParams);
    const data = await response.json();
    return data;

}