import {fetchList} from './Packages';

export const fetchPackagesData = async (name, pageNumber, setPackages, domain) => {
    try{
        const requested = await fetchList(name, pageNumber, domain);
        setPackages(requested || {});
    }catch(e){
        console.error(e);
        setPackages({})
    }   
}
