import {fetchList} from './Constructor';

export const fetchPacksData = async (setChecked, setPacks, domain) => {
    try{
        const requested = await fetchList(domain);
        setPacks(requested);
        const array = requested !== undefined && requested.map(pack => {
            let powers = [];
            let x = 0;
            while (pack.mask > -1 && x < 30) {
                if (pack.mask & 1) { 
                    powers.push(x); 
                }
                pack.mask >>= 1; 
                x++;
            }
            let arr = []
            for(let i = 0; i < 30; i++){
              arr.push(false)
            }
            powers.forEach(truer => arr[truer] = true)
            return arr;
        })
        setChecked(array || [])
    }catch(e){
        console.error(e);
        setPacks([])
    }   
}

