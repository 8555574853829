import React, {useState} from 'react';
import TypesAuto from './TypesAuto';
import { four } from './../service/rights/four';
const Modal = ({setBalance, selfRightsLvl, fetchAgain, domain, currencies, id}) => {
    const [modal, setModal]= useState(false);
    const [amount, setAmount] = useState(0);
    const [invId, setInvId] = useState(0);
    const [currency, setCurrency] = useState('');
    const [documents, setDocuments] = useState('');
    const modalSet = () => {
      setModal(!modal);
      document.body.style.overflow = !modal ? 'hidden' : 'auto';
    }
    const idChange = (event) => {
      setInvId(parseInt(event.target.value));
    }
    const amountChange = (event) => {
      setAmount(parseFloat(event.target.value));
    }
    const documentsChange = (event) => {
        setDocuments(event.target.value);
    }; 

    const addNewPackage = async() => {
      const requestBody = {
        user_id: id,
        invoice_id: invId,
        amount: amount,
        currency: currency,
        document: documents
      }
      const requestParams = {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(requestBody)
      }
      
      try{
        const responce = await fetch(`https://${domain}/api/payments`, requestParams);
        modalSet();
        if(!responce.ok) throw new Error();
        const data = await responce.json();
        setBalance(data?.balance);
        fetchAgain();
      }catch(e){
        console.error(e);
      }
    }

    return(
      <div>
        <div className='center-box' style={(selfRightsLvl< 32 ? four(selfRightsLvl) : true) ? {} : {display: 'none'}}><button className='requestBtn' onClick={modalSet}>Add</button></div>
        {modal && <div className='modal-window'>
            <div className='modal-box'>
                <div className='input-areas'>
                  <label>Amount</label>
                  <input className='inputs' type='number' onChange={amountChange} />
                </div>
                <div className='input-areas'>
                  <label>Invoice Id</label>
                  <input className='inputs' type='number' onChange={idChange} value={invId}/>
                </div>
                <TypesAuto currencies={currencies} setCurrency={setCurrency}/>
                <div className='input-areas'>
                  <label>Document</label>
                  <input className='inputs' type='text' onChange={documentsChange}/>
                </div>
                <div className='modal-btns'>
                    <button className='requestBtn' onClick={addNewPackage}>Add</button>
                    <button className='closeBtn' onClick={modalSet}>Cancel</button>
                </div>
            </div>
        </div>}
      </div>
    )
}


export default Modal