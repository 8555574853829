const RenderPagination = ({pageNumber, pageCount, setPageNumber}) => {;
      let pages = [];
      for (let i = 0; i < pageCount; i++) {
        pages.push(
          <li key={i} className={`pagination-item ${pageNumber === i ? 'active' : ''}`}>
            <button className='pagination-link-btn' onClick={() => setPageNumber(i)}>{i + 1}</button>
          </li>
        );
      }
      return (
        <ul className="pagination">
          <li className={`pagination-item ${pageNumber === 0 ? 'active' : ''}`}>
            <button className='pagination-link-btn' onClick={() => setPageNumber(0)}>1</button>
          </li>
          <li className="pagination-item disabled">
            <span>...</span>
          </li>
          {pages.slice(Math.max(0, pageNumber - 2), Math.min(pageCount, pageNumber + 3))}
          <li className="pagination-item disabled">
            <span>...</span>
          </li>
          <li className={`pagination-item ${pageNumber === pageCount - 1 ? 'active' : ''}`}>
            <button className='pagination-link-btn' onClick={() => setPageNumber(pageCount - 1)}>{pageCount}</button>
          </li>
        </ul>
      );
  };


export default RenderPagination;