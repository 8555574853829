export const two = (lvl) => {
    return (
      lvl === 2 || 
      lvl === 3 || 
      lvl === 6 || 
      lvl === 7 ||
      lvl === 10 ||
      lvl === 11 ||
      lvl === 14 ||
      lvl === 15 ||
      lvl === 18 || 
      lvl === 19 ||
      lvl === 22 ||
      lvl === 23 || 
      lvl === 26 ||
      lvl === 27 || 
      lvl === 30 || 
      lvl === 31
    )
  }