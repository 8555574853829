import React, {useState} from 'react';
const Modal = ({selfRightsLvl, fetchAgain, domain}) => {
    const [modal, setModal] = useState(false);
    const [number, setNumber] = useState("");
    const modalSet = () => {
      setModal(!modal);
      document.body.style.overflow = !modal ? 'hidden' : 'auto';
    }
    
    const numberChange = (event) => {
      setNumber(event.target.value)
    };

    const addNewDecoders = async() => {
      const requestBody = { decoders: number.split(",") }
      const requestParams = {
        method: "PUT",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(requestBody)
      }
      
      try{
        const responce = await fetch(`https://${domain}/api/decoders`, requestParams);
        modalSet();
        if(!responce.ok) throw new Error();
        fetchAgain();
      }catch(e){
        console.error(e);
      }
    }

    return(
      <div>
      <div className='center-box' style={selfRightsLvl < 32 ? {display: 'none'} : {}}><button className='requestBtn' onClick={modalSet}>Add</button></div>
      {modal && <div className='modal-window'>
            <div className='modal-box'>
                <div className='input-areas'>
                  <label>Decoder numbers</label>
                  <input className='inputs' type='text' onChange={numberChange} />
                </div>
                <div className='modal-btns'>
                    <button className='requestBtn' onClick={addNewDecoders}>Add</button>
                    <button className='closeBtn' onClick={modalSet}>Cancel</button>
                </div>
            </div>
        </div>}
      </div>
    )
}


export default Modal