const currencies = [
    'USD', 'EUR', 'JPY', 'GBP', 'AUD', 'CAD', 'CHF', 'CNY', 'SEK', 'NZD',
    'MXN', 'SGD', 'HKD', 'NOK', 'KRW', 'TRY', 'RUB', 'INR', 'BRL', 'ZAR',
    'DKK', 'PLN', 'TWD', 'THB', 'MYR', 'IDR', 'CZK', 'HUF', 'ILS', 'CLP',
    'PHP', 'AED', 'COP', 'SAR', 'RON', 'PEN', 'BGN', 'VND', 'EGP', 'KZT',
    'QAR', 'PKR', 'NGN', 'UAH', 'KES', 'ARS', 'LKR', 'MMK', 'BDT', 'BHD',
    'OMR', 'JOD', 'KWD', 'BND', 'HRK', 'ISK', 'GEL', 'DZD', 'MAD', 'TND',
    'BAM', 'MKD', 'MOP', 'ALL', 'HNL', 'UYU', 'PYG', 'CRC', 'GTQ', 'BOB',
    'NIO', 'SVC', 'HTG', 'DOP', 'JMD', 'BBD', 'XCD', 'TTD', 'GYD', 'SRD'
  ];

export default currencies