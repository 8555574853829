import React, {useEffect, useState} from 'react';
import TypeAuto from './../components/AutoComplete';

export default function BasicModal({domain, id, type, types, fetchDecodersData, pageNumber, setDecoders, fetchTypesAgain }) {
  const [open, setOpen] = useState(false);
  const setStatus = (param) => {
    setOpen(param);
    document.body.style.overflow = param ? "hidden" : "auto";
  }
  const closeModal = () => {
    setStatus(false);
  }

  const [decId, setDecId] = useState(id.toString());
  const [packType, setPackType] = useState(type);
  const [delCheck, setDelCheck] = useState(false)

  const findTypIdByName = () => {
    const index = types.findIndex(item => item.typ_name === packType);
    return index !== -1 ? types[index].typ_id : null;
  };

  const handleTypeChange = (param) => {
    setPackType(param)
  }

  const saveChanges = async () => {
    const requestBody = {
      decoder_id: decId,
      type: findTypIdByName(),
    }
    const requestParams ={
      method: "POST",
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(requestBody)
    }
    try {
      const response = await fetch(`https://${domain}/api/decoders`, requestParams);
      if(!response.ok) throw new Error();
      if(delCheck) del();
      closeModal();
      fetchDecodersData(null, pageNumber, setDecoders, domain);
    }catch(e){console.error(e);}
  }

  const del = async () => {
    try {
      const response = await fetch(`https://${domain}/api/decoders/${decId}`, {
        method: 'DELETE'
      });
      if(!response.ok) throw new Error();
    }catch(e){console.error(e);}
  }

  useEffect(() => {
    setDecId(id.toString());
  }, [open])

  return (
    <div>
      <button className='requestBtn' onClick={() => {setStatus(true)}}>Edit</button>
      {open && 
        <div className="modal-window">
          <div className='edit-modal-box modal-box'>
              <div className='input-areas'>
                <label>Name of Decoder</label>
                <input className='inputs' readOnly type='text' value={decId}/>
              </div>
              <TypeAuto types={types.map(t => t.typ_name)} type={type} setType={handleTypeChange} domain={domain} fetchAgain={fetchTypesAgain}/>
              <div><input type="checkbox" onChange={() => {setDelCheck(!delCheck)}}/>Delete decoder</div>
              <div style={{display:'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px'}}>
                <button className='requestBtn' onClick={saveChanges}>Save</button>
                <button className='closeBtn' onClick={closeModal}>Cancel</button>
              </div>
          </div>
        </div>
      }
    </div>
  );
}
